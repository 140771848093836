import React from 'react';
import puf_logo from '../images/puf_logo.png';
import puf from '../images/puf.jpg';
import './mobile_main.css';
import NoticeBoard from '../noticeboard/noticeboard';

const isMobile = true;

function MobileWebPage() {
  
  const notices = [
    { id: 1, title:'Little Seed', description: `I plant a little seed
    In the ground.
    Out comes the sun,
    Big and round.
    Down come the rain drops,
    Soft and slow.
    Up comes a flower,
    Grow, grow, grow!`}
   ];

  return (
    <div>
        <div className="title-header">
        <h3 className="puf-header">Prince Unique Foundation</h3>
        </div>
      
      <div>
        <img src = {puf} className="center-image" alt = "Image for Prince Unique Foundation!"/>
      </div>
      <div className="puf-slogan">
        <p className="puf-slogan-para"> Every Child Deserves A Quality Care. </p>
      </div>
      <NoticeBoard notices={notices} isMobile={isMobile} />
    </div>
  );
};

export default MobileWebPage;