import React from 'react';
import './noticeboard.css';

function NoticeBoard(props){
    const { notices, isMobile } = props;
    
    return (
        <div className={isMobile? "noticeboard-bg-image-mobile" : "noticeboard-bg-image"}>
            <div className="noticeboard-bg-other">
                <h2 className="notice-board">Lines on the wall!!</h2>
                {notices.map(notice => (
                <div key={notice.id} className="notification-body">
                    <h3>{notice.title}</h3>
                    {notice.description.replace(/\r\n|\r/g, '\n').split('\n').map((line, index) => {
                        return (
                        <React.Fragment key={index}>
                        {line}
                        <br />
                        </React.Fragment>
                        );
                    })}
                </div>
                ))}
            </div>
        </div>
    );
}

export default NoticeBoard;
