import React from 'react';
import {Helmet} from 'react-helmet';


function App (){
    return(
    <Helmet>
    <meta charSet="utf-8" />
    <title>Prince Unique Foundation</title>
    <link rel="canonical" href="https://www.princeuniquefoundation.in" />
    <meta name="Price Unique Foundation" content="PUF informations" />
    </Helmet>
    );
  };

export default App;