import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { purple } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import puf_logo from './images/puf_logo.png';
import puf from './images/puf.jpg';
import './main.css';
import NoticeBoard from './noticeboard/noticeboard'


const theme = createTheme();

const notices = [
  { id: 1, title:'Little Seed', description: `I plant a little seed
  In the ground.
  Out comes the sun,
  Big and round.
  Down come the rain drops,
  Soft and slow.
  Up comes a flower,
  Grow, grow, grow!`}
 ];

export default function Main() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="relative" color="primary" >
        <Toolbar>
          <img src={puf_logo} className="puf-logo" />
          <Typography variant="h3" color="inherit" noWrap>
            Prince Unique Foundation
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
          <div className="centerimage">
              <img src={puf} className="puf" />
              <NoticeBoard notices={notices} />
          </div>
      </main>
      <div className="slogan">
        <p>Every Child Deserves A Quality Care.</p>
      </div>
    </ThemeProvider>
  );
}