import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from './main';
import App from './app';
import MobileWebPage from './mobile/mobile_main';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <App />   
   {isMobile? <MobileWebPage /> : <Main/>}
  </React.StrictMode>
);


